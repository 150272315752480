
import React from "react";

import "./Navbar.css"
import "../App.css";

import logo from '../assets/logo.png'; // Tell webpack this JS file uses this image

function Footer() {


    return (
        <footer className="footer bg-secondary pt-5">
        <div className="container pt-lg-4 pb-4">
        {/* Links*/}
        <div className="row mb-5 pb-md-3 pb-lg-4">
            <div className="col-12 col-lg-9 col-sm-12 mb-lg-0 mb-4">
            <div className="row d-flex flex-sm-row flex-column justify-content-between mx-n2">
                <div className="col-lg-4 col-4 col-sm-12 mb-sm-0 mb-4 px-2"><a className="d-inline-block mb-4" href="real-estate-home.html"><img src={logo} width={116} alt="logo" /></a>
                <ul className="nav flex-column mb-sm-4 mb-2">
                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="mailto:info@fotohunters.com"><i className="fi-mail mt-n1 me-2 align-middle opacity-70" />info@fotohunters.com</a></li>
                    <li className="nav-item"><a className="nav-link p-0 fw-normal" >Paseo de la Castellana 210, Planta 7, Madrid 28046</a></li>

                </ul>
                <div className="pt-2">
                    <a className="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle me-2 mb-2" href="https://facebook.com/fotohunters"><i className="fi-facebook" /></a>
                    <a className="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle me-2 mb-2" href="https://www.instagram.com/fotohunters-com/"><i className="fi-instagram" /></a>
                    <a className="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle me-2 mb-2" href="https://www.linkedin.com/fotohunters/"><i className="fi-linkedin" /></a>
                </div>
                </div>
                <div className="col-lg-4 col-12 col-sm-12  mb-sm-0 mb-4 px-2">
                <h4 className="h5 text-dark">Páginas</h4>
                <ul className="nav flex-column">
                    
                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="/login">Inicio de sesión hunters</a></li>
                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="/register">Registro plataforma hunters</a></li>



                
                </ul>
                </div>
                <div className=" col-lg-4 col-12 col-sm-12  px-2">
                <h4 className="h5 text-dark">Sobre nosotros</h4>
                <ul className="nav flex-column">
                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal text" href="/aviso-legal">Aviso legal </a></li>
                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal text" href="/consumidores-privacidad">Privacidad Consumidores</a></li>
                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="/condiciones-contratacion">Condiciones generales de contratación</a></li>
                    <li className="nav-item mb-2"><a className="nav-link p-0 fw-normal" href="/politica-privacidad">Política de privacidad</a></li>
                </ul>
                </div>
            </div>
            </div>
        

        </div>
        {/* Banner*/}

        <div className="text-center fs-sm pt-4 mt-3 pb-2">© All rights reserved.  Street Data Sharing SL 2023 </div>
        </div>
        </footer>
    )
  

}

export default Footer;
