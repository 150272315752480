import React from "react";

import { auth,  db } from "../firebase";

import { browserHistory } from 'react-router';

import Footer from "../Shared/Footer";

import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
 

import logo from "../assets/logo-text.png"
import notFound from "../assets/banner.png"


import Comprar from "../Shared/Comprar";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import {  Offcanvas, OffcanvasHeader, OffcanvasBody ,Modal, ModalBody, Button } from 'react-bootstrap';

import MapStyle from "../Shared/MapStyle";



import  pinRojo from "../assets/pinRojo.png"; 
import  pinVerde from "../assets/pinVerde.png"; 
import NoFoto from "../assets/noFoto.png"



import CountUp from 'react-countup';


function _mapLoaded(mapProps, map) {
    map.setOptions({
      styles: MapStyle
    });
  }

  



class HuntersMapPro extends React.Component {


  constructor(props) {
    super(props) 
    this.state = {
      inmuebles: [],
      operacion: "Todas",
      propiedad: null,
      ciudad: "Madrid",
      show: false,
      loading: true,
      tab: 0,
      modalOpen: false,
      modalOpen2:true,
      selectedActive: [],
      citySelected: "Todas",
      ciudades: ["Madrid","Valencia","Valladolid", "Sevilla", "Zaragoza"],
      center: {
        lat: 40.4115,
        lng: -3.7076
    },
    zoom: 12,

    }
    this.setState({google: this.props.google})
  }

  componentDidMount() {
    this.getUser()
  }
  

  getUser = async () => {

      if(auth.currentUser) {
        db.collection('users').doc(auth.currentUser.email).get()
        .then(doc => {
            const data = doc.data();
    
    
            this.setState({user:data})
            if(data.pro === true) {
                this.setState({ pro: true})
            }
            else {
              this.setState({ pro: false})
              
            }
        })
  
      } 
      setTimeout(() => {
          this.getInmuebles()
      }, 2000);
    
 
}




renderActivosLista =  () => {
  var data = this.state.inmuebles;

  data = this.filterOperacion(data);
  data = this.filterPropiedad(data)
  data = this.filterCity(data)

  if(data.length > 0 && this.state.loading !== true) {

    return(data.map(item => this.renderActivoLista(item)) )
  } else {
    return (
      <>
      <div className="container mt-5 text-center align-items-center ">

        <img src={notFound} className="img-fluid" width={"200px"}></img>
        <h4>No se ha encontrado ningún activo con esos filtros</h4>

      </div>
      </>
    )
  }
}


renderActivoLista(item)  {

  return(
          <div class="card   shadow-sm m-2 " onClick={() => this.setActivo(item)}>
          <div class="d-flex d-md-block d-lg-flex  ">
            <div class="thumbnailCard m-3">
                <img class=" " src={item.img || NoFoto}  />
            </div>
            <div class="m-3">
              <label class=" mb-0">{item.calle}</label>
              <br/>
              <span class="badge bg-faded-accent pl-1">{item.propiedad}</span>
              <span class="badge bg-faded-success mr-1">{item.operacion}</span>
            </div>
            
            </div>
            
        </div>
  )



}


renderActivo(item)  {

  if(item.lat && item.lng) {

    var text = item.lng.toString();
    var lng = Number(text)
    var textlat = item.lat.toString();
    var lat = Number(textlat)
    
      if(item.agencia !== true) {
        return(
  
          <Marker
              key={item.id}
              onClick={() => this.setActivo(item)}
              // onMouseover={() =>  this.setActivo(item)}
              name={'Your position'}
              position={{lat: lat, lng: lng}}
              icon={{
                url:pinRojo,
                anchor: new this.props.google.maps.Point(50,50),
                scaledSize: new this.props.google.maps.Size(50, 50)
              }}
              />
      )
      } else {
        return(
          <Marker
          key={item.id}
          onClick={() => this.setActivo(item)}
          // onMouseover={() =>  this.setActivo(item)}
          name={'Your position'}
          position={{lat: lat, lng: lng}}
          icon={{
            url:pinVerde,
            anchor: new this.props.google.maps.Point(50,50),
            scaledSize: new this.props.google.maps.Size(50, 50)
          }}

          />
        )
      }

    
  
    
  }
  
  }

  setActivo(item) {
    if(window.innerWidth <=  768) { 
      this.setState({selectedActive:item, show: true})
    } else {
        this.setState({selectedActive:item})

    }
  }

  renderActivos =  () => {
    var data = this.state.inmuebles;

    data = this.filterOperacion(data);
    data = this.filterPropiedad(data)
        
    return(data.map(item => this.renderActivo(item)) )
  }



  addInmueble(newItem) {
    this.setState({ inmuebles: [...this.state.inmuebles, newItem] })
  }

  async  getActivos() {
    try {
      let response = await fetch('https://us-central1-fotohunters-com.cloudfunctions.net/app/activos');
      let responseJson = await response.json();
      return responseJson;
     } catch(error) {
      console.error(error);
    }
  }

  async getInmuebles() {
      this.setState({inmuebles:[]})
      // GET ACTIVOS 
      await this.getActivos().then((json) => this.setState({loading:false, inmuebles:json}))
  
  }

  filterOperacion = (array) => {
    if (this.state.operacion && this.state.operacion !== "Todas") {
          return array.filter((item) => item.operacion === this.state.operacion);
      } else {
          return array;
      }
  };




    filterPropiedad = (array) => {
      if (this.state.propiedad !== "Todas") {
            return array.filter((item) => item.propiedad === this.state.propiedad);
        } else {
            return array;
        }
    };



    filterCity = (array) => {
      if (this.state.citySelected !== "Todas") {
            return array.filter((item) => item.ciudad === this.state.city);
        } else {
            return array;
        }
    };


  renderMenu() {
    const inmueble = this.state.selectedActive;

    if(window.innerWidth <=  768) {
      return(
      <Offcanvas show={this.state.show} className="offcanvas offcanvas-start offcanvas-collapse" id="filters-sidebar">
      <OffcanvasHeader className="offcanvas-header d-flex  d-lg-none align-items-center" >
        <h2 className="h5 mb-0">Ficha activo</h2>
        <button className="btn-close" type="button" data-bs-dismiss="offcanvas" onClick={() => this.setState({show:false})} />
      </OffcanvasHeader>
     
      <OffcanvasBody className="offcanvas-body py-lg-4">
     
      <>
            <nav className="mb-3 pt-md-3 text-small-mobile" aria-label="breadcrumb">
              <ol className="breadcrumb text-small-mobile">
                <li className="breadcrumb-item text-small-mobile"> <a>FotoHunters</a></li>
                <li className="breadcrumb-item text-small-mobile">  <a>Activos</a> </li>
                {/* <li className="breadcrumb-item active text-small-mobile" aria-current="page">{inmueble.id}</li> */}
              </ol>
            </nav>

            <p className="mb-2 pb-1 fs-lg">{inmueble.calle}</p>
            {/* Features + Sharing*/}
            <div className="d-flex justify-content-between align-items-center">
             
            
            
            </div>


            <div class="row  " data-thumbnails="true">
              <div class="col-12 d-flex align-items-center justify-content-center">
                  <img src={inmueble.img || NoFoto}  width={"200px"} height={"200px"} className="" alt="Gallery thumbnail" />
              </div>
              <div className="col-md-12 mt-2">
                <span className="badge bg-success me-2 mb-3">Verificado</span>
        
                <div className="mb-4 pb-md-3">
                {inmueble.calle ?  <h4><b></b>{inmueble.calle}</h4> : null}

                {inmueble.nHabitaciones? 
                    <ul class="d-flex mb-4 list-unstyled">
                        <li class="me-3 pe-3 border-end"><b class="me-1">{inmueble.nHabitaciones}</b><i class="fi-bed mt-n1 lead align-middle text-muted"></i></li>
                        <li class="me-3 pe-3 border-end"><b class="me-1">{inmueble.nBaños}</b><i class="fi-bath mt-n1 lead align-middle text-muted"></i></li>
                        <li><b>{inmueble.metros} </b>m2</li>
                      </ul>
                    : 
                    <></>
                    }


                <ul className="list-unstyled mb-0 ">

                    <li><b>Categoría: </b>{inmueble.propiedad}</li>
                    <li><b>Tipo </b>{inmueble.operacion}</li>

                    {inmueble.numero ?  <li> <br/> <a className="btn btn-secondary" href={`tel:+34${inmueble.numero}`}> <i className="fi-phone fs-base me-2" /> Contactar número </a> </li> : 
                    <>
                    <br/>
                    {inmueble.link ?  <li> <br/> <a className="btn btn-secondary" target={"__blank"} href={`${inmueble.link}`}> <i className="fi-link fs-base me-2" /> Link activo </a> </li> : null }

                    </>
                    }


                    {inmueble.imgmap ?
                    
                    <div className=" col-12 col-md-12">
                    <h5>Agencia</h5>
                    {inmueble.descripcion ? <b>{inmueble.descripcion} </b>: null} <br/>
                    <img src={inmueble.imgmap} className="img-fluid" width={"50px"}/> <br/>
                    <a href={inmueble.linkAgencia} className="bold underline cursor">Ver listado completo de agencia </a>

                    </div>
                   : null}
                  
                  </ul>

                  {inmueble.precio !== 123456 && inmueble.precio !== null  && inmueble.precio !== undefined && inmueble.precio !== "" ? <>
                    <h4>Precio </h4> <p>{inmueble.precio}€ </p>
                    </>: null}

                  <div class="alert alert-warning d-flex mt-2" role="alert">
                    <i class="fi fi-alert me-2"></i>
                  <div><label><b>FotoHunters</b> No se hace responsable de los contenidos publicados</label></div>
                </div>
            
                </div>             
              </div>
            </div>

          </>
    
      </OffcanvasBody>
    </Offcanvas>
      )
    } else {
        return ( 

        <div className="">
        <div className=" mt-5  ">
        <section className="container pt-0 mt-3 ">
        <>
            <nav className="mb-3 pt-md-3 text-small-mobile" aria-label="breadcrumb">
              <ol className="breadcrumb text-small-mobile">
                <li className="breadcrumb-item text-small-mobile"> <a>FotoHunters</a></li>
                <li className="breadcrumb-item text-small-mobile">  <a>Activos</a> </li>
                {/* <li className="breadcrumb-item active text-small-mobile" aria-current="page">{inmueble.id}</li> */}
              </ol>
            </nav>

            <p className="mb-2 pb-1 fs-lg">{inmueble.calle}</p>
            {/* Features + Sharing*/}
            <div className="d-flex justify-content-between align-items-center">
             
            
            
            </div>


            <div class="row  " data-thumbnails="true">
              <div class="col-12 d-flex align-items-center justify-content-center">
                  <img src={inmueble.img || NoFoto}  width={"200px"} height={"200px"} className="" alt="Gallery thumbnail" />
              </div>
              <div className="col-md-12 mt-2">
                <span className="badge bg-success me-2 mb-3">Verificado</span>
        
                <div className="mb-4 pb-md-3">
                {inmueble.calle ?  <h4><b></b>{inmueble.calle}</h4> : null}

                {inmueble.nHabitaciones? 
                    <ul class="d-flex mb-4 list-unstyled">
                        <li class="me-3 pe-3 border-end"><b class="me-1">{inmueble.nHabitaciones}</b><i class="fi-bed mt-n1 lead align-middle text-muted"></i></li>
                        <li class="me-3 pe-3 border-end"><b class="me-1">{inmueble.nBaños}</b><i class="fi-bath mt-n1 lead align-middle text-muted"></i></li>
                        <li><b>{inmueble.metros} </b>m2</li>
                      </ul>
                    : 
                    <></>
                    }

                    <ul className="list-unstyled mb-0 ">

                    <li><b>Categoría: </b>{inmueble.propiedad}</li>
                    <li><b>Tipo </b>{inmueble.operacion}</li>

                    {inmueble.numero ?  <li> <br/> <a className="btn btn-secondary" href={`tel:+34${inmueble.numero}`}> <i className="fi-phone fs-base me-2" /> Contactar número </a> </li> : 
                    <>
                    <br/>
                    {inmueble.link ?  <li> <br/> <a className="btn btn-secondary" target={"__blank"} href={`${inmueble.link}`}> <i className="fi-link fs-base me-2" /> Link activo </a> </li> : null }

                    </>
                    }


                    {inmueble.imgmap ?

                    <div className=" col-12 col-md-12">
                    <h5>Agencia</h5>
                    {inmueble.descripcion ? <b>{inmueble.descripcion} </b>: null} <br/>
                    <img src={inmueble.imgmap} className="img-fluid" width={"50px"}/> <br/>
                    <a href={inmueble.linkAgencia} className="bold underline cursor">Ver listado completo de agencia </a>

                    </div>
                    : null}









                    </ul>

                  {inmueble.precio !== 123456 && inmueble.precio !== null  && inmueble.precio !== undefined && inmueble.precio !== "" ? <>
                    <h4>Precio </h4> <p>{inmueble.precio}€ </p>
                    </>: null}

                  <div class="alert alert-warning d-flex" role="alert">
                    <i class="fi fi-alert me-2"></i>
                  <div><label><b>FotoHunters</b> No se hace responsable de los contenidos publicados</label></div>
                </div>
            
                </div>
                
      
    


             
              </div>
            </div>
            <div className="col-12  ">
          <div class="alert alert-secondary" role="alert">
            <b>¿Necesitas ayuda?</b> <br/>
            Contacta con nosotros por <b>Whatsapp</b> <br/>
            <div className="m-2">
            <a  target={"__blank"}  href="https://wa.me/34691800608" className="text-decoration-none">
            <div className="btn btn-success d-flex justify-content-center align-items-center text-center"> <i className="fi fi-whatsapp p-1"></i> Chatear</div>
            </a>
          </div>

          </div>
        </div>

          </>
        </section>
        </div>  
        </div>            
      )
    }
  }

 



  handleChangeCity = (event) => {
    this.setState({ city: event.target.value });
    var obj = this.state.inmuebles.find(x => x.ciudad === event.target.value)
    this.setState({
      center: {
        lat: obj.lat,
        lng: obj.lng
    },
    zoom: 10,
    citySelected: event.target.value,
    })
    // var lng = obj[0].lng
    // alert(lng, lat)

  }


  goToActivo(item) {

      if(item.propiedad === "Locales" && this.state.pro !== true) {
        this.setState({modalOpen: true})

      } else {
        window.open(`https://fotohunters.com/activo/${item.id}`, '_blank');

    }
  }


  renderTabs() {
    if(this.state.tab === 0) {
      return (
        <>
         
         <Map
            defaultOptions={{
                styles: MapStyle,
            }}
    
            google={this.props.google}
            style={{width:  "93%",height: "60vh", minHeight: "500px", marginTop:0 , borderRadius:30, top: 0, zIndez:-1}}
            initialCenter={this.state.center}
            center={this.state.center}
            onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
            disableDefaultUI={false}
            scrollwheel={true}
            zoom={this.state.zoom}
            
            >

              {this.renderActivos()}
            

          </Map>

        
         
        </>
      )
    }
    
    else if(this.state.tab === 1) {
      return (
        <>
         <ul class="list-group">
            {this.renderActivosLista()}
          </ul>
        </>
      )
    } 
   
  }


  changeTab() {
    if(this.state.tab !== 1) {
      this.setState({tab:1})
    } else {
      this.setState({tab:0})
    }
  }
  

  render() {

      return (
        <>

<Modal show={this.state.modalOpen} class="modal center"  id="modalCentered"  centered>
               
                <ModalBody class="modal-body text-center">

                <h3 class="modal-title">Hazte premium y accede al mapa</h3>
                  <div className="row">
                    <div className="col-6">
                      <img  className="oferta" height="100px" src="./assets/logo.png"></img>
                    </div>
                    <br/>
                    <div className="col-6">
                    <br/> <br/> 
                    Para poder usar <b>Huntersmaps</b> adquiere la subscripción premium
                    <br/>
                    <Comprar></Comprar>
                    </div>

                  </div>
                 
                  

                </ModalBody>
            
          </Modal>
        { this.state.propiedad !== null ?
        <main>
        <div>
          {this.state.loading === true ?
          <>
          <div class="loading">Loading&#8230;</div>
          </>
          : null}
        <div className="mt-5">
        <div className="bg-light mt-5">
     
        <div className="container-fluid mt-0 pt-3 p-0">
        <div className="row g-0 " >
          {/* Filters sidebar (Offcanvas on mobile)*/}
         
          {/* Page content*/}
          <div className="col-lg-8 col-xl-9 position-relative pb-5  mt-5 px-3 px-xl-4 px-xxl-5 minHe  ">
          <div>
          <Modal show={ true} class="modal center"  id="modalCentered"   centered>
                <ModalHeader class="modal-header"  >
                  <h5 class="modal-title">Aviso cambios 2024</h5>
               
                </ModalHeader>
                <ModalBody class="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <img  className="" height="" src={logo}></img>
                    </div>
                    <br/>
                    <div className="mt-2 justify-content-center align-items-center text-center text-danger">
                   
                    <br/>

                    FOTO HUNTERS ESTÁ REALIZANDO  UNA  ADAPTACIÓN DE SUS SERVICIO PARA CUMPLIR CON LAS NUEVA  NORMATIVA SOBRE  DE PROTECCIÓN DE DATOS  POR LO QUE QUEDA SUSPENDIDOS LOS SERVICIOS DE SUSCRIPCIÓN Y ALERTAS DE ACTIVOS HASTA ADAPTAR NUESTRA ACTIVIDAD A LA NORMATIVA ACTUAL .
                    <br/>
                    <br/>

                    Sentimos las molestias ocasionadas .
                    <br/>
                    <br/>

                    TODAS LAS SUSCRIPCIONES ACTIVAS SERÁN CANCELADAS Y REINTEGRADO LOS IMPORTES ABONADOS  DESDE EL 15/10/2024 EN ADELANTE.
                    <br/>
                    <br/>

                    ESTAMOS A SU DISPOSICIÓN EN EL 691-800-608 O info@fotohunters.com
                    <br/>
                    <br/>


                    </div>



                  </div>
                </ModalBody>

                <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({propiedad: null})}>
                  Cerrar
                </Button>
                {/* <Comprar /> */}

              </Modal.Footer>
            
          </Modal>


        


       
        
            <div className="row ">
           

              <div className="col col-12 col-md-4 ">
                  <label>Selecciona ciudad</label>
                  <select onChange={this.handleChangeCity}  className="form-select mb-2">
                    <option value="Madrid" >Madrid</option>
                    <option value="Valencia" >Valencia</option>
                    <option value="Valladolid" >Valladolid</option>
                    <option value="Sevilla" >Sevilla</option>
                    <option value="Zaragoza" >Zaragoza</option>

                  </select>
              </div>
      
              <div className="col-12 col-md-4  ">
              <label>Operacion</label>
              <ul className="nav nav-tabs ">
                
                    <li className="nav-item"><a  className={this.state.operacion === "Alquiler" ? "nav-link active" : "nav-link "}  onClick={() => this.setState({operacion: "Alquiler"})}><i className="fi-rent fs-base me-2" />Alquiler</a></li>
                    <li className="nav-item"><a className={this.state.operacion === "Venta" ? "nav-link active" : "nav-link "}    onClick={() => this.setState({operacion: "Venta"})}><i className="fi-home fs-base me-2" />Venta</a></li>

                     <li class="nav-item d-flex align-items-center justify-content-center" onClick={() => this.changeTab()}>
                    <i className={this.state.tab === 1 ? "fi fi-map" : "fi fi-list "}  ></i>
              </li>

                  </ul>
              </div>


            <div className="col-12 col-md-4  d-flex align-items-center   pb-3 ">
            <div class=" " role="alert">
                    <div><label><img src={pinVerde} width={20} height={20}></img><b>Agencias  </b>    <img src={pinRojo} width={20} className="mr-2" height={20}></img> <b>Particulares</b> </label></div>
                  </div>
              </div>
              
            
            </div>
           
           
            {this.renderTabs()}

          
          
          </div>
          

          </div>    

          <aside className="col-lg-4 col-xl-3 border-top-lg border-end-lg shadow-sm px-3 px-xl-4 px-xxl-5 pt-lg-2 minHe" >
          {this.renderMenu()}
          </aside>
          


      

        
        <br/>

         </div>
          
      </div>
           

        <Footer></Footer>

        {/* <button style={{minHeight: "45px"}} onClick={() => this.setState({show: !this.state.show})} className="btn btn-primary btn-sm w-100 rounded-0 fixed-bottom d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#filters-sidebar"><i className="fi-filter me-2"></i>Filtros </button> */}
      </div>
      </div>

      </div>

      </main> :

        <main>
          <div class="row justify-content-center align-items-center  py-5 m-4  backrester mx-auto vh-100">
          <div class="col-sm-10 col-md-6 col-lg-5 col-xl-5 col-xxl-5  m-5">
          <div class="card card-body text-center p-5  ">
          <div class="justify-content-center align-items-center ">
          

          
          <h3 class=" m-2 pb-2">Bienvenido a Fotohunters</h3>
         

           <div class="alert alert-primary d-flex center justify-content-center  " role="alert">
            <i class="fi-bell me-2 me-sm-3 lead"></i>
            <div className="   text-center">AHORA <b>HUNTERSMAP</b> COBERTURA NACIONAL!</div>
          </div>

          <b><CountUp end={4310} /> Suscripciones a Fotohunters PRO en 2023</b>

          <br/>
            <br/>
          <a>
            <button type="button" class="btn btn-lg bg-xxl btn-secondary mb-2 fixWidth"  onClick={() => this.setState({propiedad: "Viviendas"})}>
              <i class="fi-home me-2"></i>
              <b>HUNTERSMAP</b> residencial
            </button>

          </a>

          <br/>

           <a>
            <button type="button" class="btn btn-lg btn-secondary fixWidth" onClick={() => this.setState({propiedad: "Locales"})}>
              <i class="fi-rent me-2"></i>
              <b>HUNTERSMAP</b> RETAIL <b>(PRO)</b>
            </button>

          </a>

          </div>
          </div>
          </div>
          </div>

          <Footer/>


        </main>



        }
        </>
      );

    }
  }


  export default GoogleApiWrapper({
    apiKey: ("AIzaSyBIayr68wF2Oax1LU8i7dIrJK6rxqEcQCk")
  })(HuntersMapPro)
